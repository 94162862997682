import React from "react"
import Header from "../components/Header.js"
import Footer from "../components/Footer.js"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

function About() {
  return (
    <>
      <Header />
      <main>
        <Grid container justify="center" alignItems="center" direction="column">
          <Box m={3}>
            <h1>Privacidade</h1>
          </Box>
          <img
            className="me-img-content"
            src="../img/lorem_ipsum.svg"
            height="250px"
            alt="Lorem ipsum"
          ></img>
          <Box m={5}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Box>
        </Grid>
      </main>
      <Footer />
    </>
  )
}

export default About
